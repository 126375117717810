import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './firebase'
// import reauthenticate from './utils/reauthenticate'
// import  auth from './utils/auth'
import './plugins/mixins'
import loadData from './utils/loadData'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

Vue.config.productionTip = false

let initialized = false

// const wait = (sec)=>{
//   return new Promise(resolve=>{
//     setTimeout(resolve, sec*1000)
//   })
// }

firebase.auth().onAuthStateChanged(async (user) => {
  console.log('-----------------------------------------------------------')
  console.log({user})

  store.commit('UPDATE_USER', user)
  await loadData(user)
  console.log('-----------------------------------------------------------')
  console.log('DONE')
  console.log('-----------------------------------------------------------')
  console.log('-----------------------------------------------------------')
  console.log('-----------------------------------------------------------')
  console.log('-----------------------------------------------------------')

  // if(user){
  //   await auth.getUserData()
  //   await wait(.5)
  // }

  console.log('DATA LOADED')
  if (!initialized) {
    console.log('Not Initiliazed', user)
    // if (user) {
    //   firebase.auth().currentUser.getIdTokenResult()
    //     .then(async (idTokenResult) => {
    //       console.log({ idTokenResult })
    //       console.log(idTokenResult.token)
    //       await reauthenticate()
    //       console.log(idTokenResult.token)
    //       // let result = await msGraph(idTokenResult.token)
    //     })
    //   //   firebase.auth().currentUser.getIdTokenResult()
    //   // .then((idTokenResult) => {
    //   //   console.log(2, {idTokenResult})
    //   // })

    // }
    initialized = true
    console.log('INIT 1')
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
    console.log('INIT 2')
  }
})

