<template>
  <v-card>
    <v-card-text>
      HOME
    </v-card-text>
  </v-card>

</template>

<script>
import { mapState } from 'vuex'




  export default {
    name: 'Home',
    computed:{
      ...mapState(['msGraph'])
    },
    components: {
    },
  }
</script>
