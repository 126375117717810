import firebase from '../firebase'
import auth from './auth'
import store from '../store'

const db = firebase.firestore()

// let initialized
let unsubscribe = {}

// firebase.auth().onAuthStateChanged(async (user) => {


//     // else {
//     //     let userToken = await auth.getClaims()
//     //     if (userToken?.claims?.access?.role === 'dev') {
//     //         loadConfig()
//     //     }
//     // }
//     // if (!initialized){
//     //     await loadData()
//     // }

//     // let claimData = await auth.getClaims()
//     // let access = claimData.claims.access
//     // await getPages(access)
//     // await getClients(access)
//     // await getDashboards(access)
//     console.log('-----------------------------------------------------------')
// })

const loadConfig = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log('loadConfig for',{access})
            if (!access){
                console.log('No Access')
                return resolve()
            }
            console.log({ unsubscribe })
            if (unsubscribe.permissions) {
                unsubscribe.permissions()
            }
            let query = db.collection("config").doc('permissions')
            console.log({query})
            if (!query.onSnapshot){
                console.log('NO SNAP')
                return {}
            }
            console.log('PRE PERMS')
            unsubscribe.permissions = query.onSnapshot((querySnapshot) => {
                console.log('-----------------PERMISSIONS')
                let result = querySnapshot.data()
                console.log(result)
                store.commit('UPDATE_PERMISSIONS', result)
                resolve(result)
            })
            return unsubscribe.permissions
        } catch (err) {
            reject(err)
        }
    })
}

let authInitialized = false
let publicPagesLoaded = false

const loadDataNew = async (user) => {
    console.log('loadDataNewloadDataNewloadDataNewloadDataNew')
    if (!publicPagesLoaded) {
        console.log('LOAD PUBLIC PAGES')
        await getPublicPages()
        console.log('DONE LOAD')
    }
    if (!user || authInitialized) {
        console.log('NO USER OR ALREADY initted')
        return
    }
    console.log('PRE LOAD OTHYERS')
    // let user = store.state.user
    await Promise.all([getPublicPages(), getProfile(user)])
    console.log('DONE PROFILE AND USER', user)
    let userToken = await auth.getClaims()
    console.log('userToken', userToken)
    console.log('LoadConfig ? ', userToken)
    if (userToken?.claims?.access?.role === 'dev') {
        loadConfig(userToken?.claims?.access)
    }
    
    let claimData = await auth.getClaims()
    let access = claimData?.claims?.access || null
    console.log(access)
    if (unsubscribe) {
        Object.keys(unsubscribe).forEach(async key => {
            await unsubscribe[key]()
        })
    }
    unsubscribe = {}

    await getPublicPages()

    if (user && store?.state?.auth?.claims?.access) {
        console.log(store?.state?.auth?.claims?.access)
        await Promise.all([
            await getPages(access),
            await getProfile(user),
            await getClients(access),
            await getDashboards(access),
            await getBookmarks(access),
        ])
    }
    return
}
console.log(loadDataNew)
const loadData = async (user) => {
    console.log('-----------------------------------------------------------')
    console.log('loadData', user)
    console.log('-----------------------------------------------------------')
    if (!user) {
        console.log('No User')
        store.commit('LOGOUT_USER')
        await getPublicPages()
        return
    }
    let claimData = await auth.getClaims()

    let access = claimData?.claims?.access || null
    if (!access || !access.role){
        console.log('No Access')
        await getPublicPages()
        return
    }
    console.log('0000000000000000000000000000000',{access, claimData})
    let result = await Promise.all([
        getPublicPages(),
        getPages(access),
        getClients(access),
        getDashboards(access),
        // getImportDatasets(access),
        getBookmarks(access),
        getPhoneSchedules(access),
        getProfile(user),
        // loadConfig(access),
    ])
    if (claimData?.claims?.access?.role === 'dev') {
        loadConfig(claimData?.claims?.access)
    }
    console.log({ result }, getImportDatasets, getPhoneSchedules)
    console.log(result)
    store.commit('SET_DATA_LOADED')
    console.log('-----------------------------------------------------------')


    return result


    // if (authDataInit || 1==1) {
    //     console.log('SKIPPPPPP')
    //     return
    // }
    // let user = store.state.user
    // let claimData = await auth.getClaims()
    // let access = claimData?.claims?.access || null
    // if (user && access) {
    //     authDataInit = true
    //     await Promise.all([
    //         await getPages(access),
    //         await getClients(access),
    //         await getDashboards(access),
    //         await getBookmarks(access),
    //     ])
    // }
}



const getDashboards = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log({ unsubscribe })
            if (unsubscribe.dashboards) {
                unsubscribe.dashboards()
            }
            let query = db.collection("dashboards")
            if (access?.clients?.length) {
                query = query.where("access.clients", 'array-contains-any', access.clients)
            }

            query = query.where(`access.roles.${access?.role || 'unknown'}`, '==', true)

            unsubscribe.dashboards = query.onSnapshot((querySnapshot) => {
                let result = []
                querySnapshot.forEach((doc) => {
                    console.log('NEW dashboards', doc)
                    result.push(doc.data())
                })
                result.sort((a, b) => a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0)
                store.commit('UPDATE_DASHBOARDS', result)
                resolve(result)
            })
            return unsubscribe.dashboards
        }
        catch (err) {
            return reject(err)
        }
    })
}

const getImportDatasets = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log({ unsubscribe })
            if (unsubscribe.importDatasets ) {
                unsubscribe.importDatasets()
            }
            let query = db.collection("importDatasets")
            if (access?.clients?.length) {
                query = query.where("access.clients", 'array-contains-any', access.clients)
            }

            query = query.where(`access.roles.${access?.role || 'unknown'}`, '==', true)

            unsubscribe.importDatasets = query.onSnapshot((querySnapshot) => {
                let result = []
                querySnapshot.forEach((doc) => {
                    console.log('NEW importDatasets', doc)
                    result.push(doc.data())
                })
                result.sort((a, b) => a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0)
                store.commit('UPDATE_IMPORT_DATASETS', result)
                resolve(result)
            })
            return unsubscribe.importDatasets
        }
        catch (err) {
            return reject(err)
        }
    })
}


const getPhoneSchedules = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log({ unsubscribe })
            if (unsubscribe.schedules) {
                unsubscribe.schedules()
            }
            let query = db.collection("phone_schedules")
            if (access?.clients?.length) {
                query = query.where("access.clients", 'array-contains-any', access.clients)
            }

            query = query.where(`access.roles.${access?.role || 'unknown'}`, '==', true)

            unsubscribe.schedules = query.onSnapshot((querySnapshot) => {
                let result = []
                querySnapshot.forEach((doc) => {
                    console.log('NEW schedules', doc)
                    result.push(doc.data())
                })
                store.commit('UPDATE_PHONE_SCHEDULES', result)
                resolve(result)
            })
            return unsubscribe.schedules
        }
        catch (err) {
            return reject(err)
        }
    })
}

// const getDashboards = async (access) => {
//     console.log('getDashboards', access)

//     let result = []
//     let query = db.collection("dashboards")
//     console.log('9000000000000000000000000000000000000000000000000000000000')

//     if (access?.clients?.length) {
//         // let clients = [...access.clients, 'any']
//         //    query = query.where(firebase.firestore.FieldPath.documentId(), 'in', clients)
//         query = query.where("access.clients", 'array-contains-any', access.clients)

//     }
//     console.log('9000000000000000000000000000000000000000000000000000000000')

//     if (access?.role) {
//         query = query.where(`access.roles.${access.role}`, '==', true)
//     }

//     await query.onSnapshot((querySnapshot) => {
//         result = []
//         console.log('SNAPSHTO CHANGED', 'getDashboards', querySnapshot)

//         querySnapshot.forEach((doc) => {
//             console.log('NEW PAGE', doc)
//             result.push(doc.data())
//         })
//         result.sort((a, b) => a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0)
//         store.commit('UPDATE_DASHBOARDS', result)
//         console.log('DONE DASHBOARDS')
//     })

//     // query = await query.get().catch((error) => {
//     //     return { error }
//     // })
//     // if (!query.error) {
//     //     query.forEach((doc) => {
//     //         result.push(doc.data())
//     //     })
//     // }
//     // store.commit('UPDATE_DASHBOARDS', result)
//     return result
// }


const getProfile = (user) => {
    return new Promise((resolve, reject) => {

    try {
        if (unsubscribe.profile) {
            unsubscribe.profile()
        }
        console.log('loadpprofile', user)

        if (!user) {
            store.commit('UPDATE_PROFILE', null)
            return null
        }
        let query = db.collection("profiles").where('uid', '==', user.uid)
        unsubscribe.profile = query.onSnapshot((querySnapshot) => {
            let result = null
            querySnapshot.forEach((doc) => {
                result = doc.data()
                console.log('NEW PROFILE')
            })
            store.commit('UPDATE_PROFILE', result)
            resolve(result)
        })
        // .catch((error) => {
        //     console.log("Error getting profile: ", error)
        //     return { error }
        // })
        return unsubscribe.profile
    }
    catch (err) {
        console.log(err)
        return reject(err)
    }
})
}



const getBookmarks = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log({ access, unsubscribe })
            if (unsubscribe.bookmarks) {
                unsubscribe.bookmarks()
            }
            let query = db.collection("bookmarks")
            if (!access?.allClients && access?.clients?.length) {
                console.log('access?.clients',access?.clients)
                query = query.where(`access.clients`, 'array-contains-any', access.clients)
            }
            console.log(access?.role)
            query = query.where(`access.roles.${access?.role || 'unknown'}`, '==', true)

            unsubscribe.bookmarks = query.onSnapshot((querySnapshot) => {
                console.log({ querySnapshot })
                let result = {}
                querySnapshot.forEach((doc) => {
                    console.log('NEW Bookmark', doc)
                    result[doc.id] = doc.data()
                })
                store.commit('UPDATE_BOOKMARKS', result)
                resolve(result)
            })
            return unsubscribe.bookmarks
        }
        catch (err) {
            console.log(err)
            return reject(err)
        }
    })
}


const getClients = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log('getClients', { access, unsubscribe })
            if (unsubscribe.clients) {
                unsubscribe.clients()
            }
            let query = db.collection("clients")
            if (access?.clients?.length) {
                query = query.where(firebase.firestore.FieldPath.documentId(), 'in', access.clients)
            }

            unsubscribe.clients = query.onSnapshot((querySnapshot) => {
                console.log({ querySnapshot })
                let result = []
                querySnapshot.forEach((doc) => {
                    console.log('NEW CLIENT', doc)
                    result.push(doc.data())
                })
                result.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                store.commit('UPDATE_CLIENTS', result)
                resolve(result)
            })

            return unsubscribe.clients
        }
        catch (err) {
            console.log(err)
            return reject(err)
        }
    })
}

const getPages = (access) => {
    return new Promise((resolve, reject) => {
        try {
            console.log('getPages', { access, unsubscribe })
            if (unsubscribe.pages) {
                unsubscribe.pages()
            }
            let query = db.collection("pages")
            if (!access?.role) {
                return resolve([])
            }
            query = query.where(`access.roles.${access.role}`, '==', true)

            unsubscribe.pages = query.onSnapshot({includeMetadataChanges:false},(querySnapshot) => {
                console.log(querySnapshot, querySnapshot.metadata)
                let result = []
      
                querySnapshot.forEach((doc) => {
                    console.log('NEW PAGE', doc)

                    result.push(doc.data())
                })
                result.sort((a, b) => a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0)
                store.commit('UPDATE_PAGES', result)
                resolve(result)
            })
            return unsubscribe.pages
        }
        // return result
        catch (err) {
            console.log(err)
            return reject(err)
        }
    })
}



const getPublicPages = () => {
    try {
        return new Promise(resolve => {
            let query = db.collection("pages")
            query = query.where(`access.public`, '==', true)
            console.log(query)
            return query.onSnapshot((querySnapshot) => {
                console.log(querySnapshot)
                
                let result = []
                querySnapshot.forEach((doc) => {
                    console.log('NEW Public PAGE', doc)
                    result.push(doc.data())
                })
                result.sort((a, b) => a.weight < b.weight ? -1 : a.weight > b.weight ? 1 : 0)
                store.commit('UPDATE_PUBLIC_PAGES', result)
                console.log('PAGES')
                console.log('DONE UPDATE_PUBLIC_PAGES')

                return resolve(result)
            })
        })
    }
    catch (err) {
        console.log(err)
        return err
    }
}


export default loadData