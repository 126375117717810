<template>
  <v-app id="hub">
    <v-navigation-drawer :mini-variant.sync="drawer" app dark clipped permanent>
      <v-list dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in allPages"
            :key="item.path"
            :to="item.path"
            @click="drawer = !drawer"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" @mouseenter="drawer = false"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!--  -->
    </v-navigation-drawer>

    <v-app-bar app dense dark clipped-left>
      <v-toolbar-title>VIPdesk HUB</v-toolbar-title>
      <v-spacer></v-spacer>
      <transition name="fade">
        <v-btn color="green" class="mr-3" v-if="saving"
          >...Saving
          <v-icon> mdi-content-save mdi-spin </v-icon>
        </v-btn>
      </transition>
      <UserMenu />
    </v-app-bar>

    <v-main class="d-flex flex-column">
      <router-view class="ma-5"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UserMenu from "./components/UserMenu.vue";
export default {
  data: () => ({
    test: false,
    drawer: true,
    selectedItem: 1,
    items: [
      { text: "Home", icon: "mdi-home", to: "/" },
      {
        text: "Bookmarks",
        icon: "mdi-bookmark",
        path: "/bookmarks",
        to: "/bookmarks",
      },
      { text: "Speed Test", icon: "mdi-speedometer", to: "/speedtest" },
      { text: "Reporting", icon: "mdi-table-large", to: "/reporting" },
      {
        text: "Dashboards",
        icon: "mdi-view-dashboard-variant",
        to: "/dashboards",
      },
      { text: "Account Services", icon: "mdi-account-edit", to: "/account" },
      { text: "Data Import", icon: "mdi-database-import", to: "/import" },
      { text: "Status", icon: "mdi-list-status", to: "/status" },
      { text: "Messaging", icon: "mdi-android-messages", to: "/messaging" },
      {
        text: "Phone Schedule",
        icon: "mdi-phone-check",
        to: "/phone-schedule",
      },
      { text: "Admin", icon: "mdi-cog", to: "/admin" },
    ],
  }),
  components: {
    UserMenu,
  },
  computed: {
    ...mapGetters(["allPages"]),
    ...mapState(["saving"]),
  },
};
</script>
<style>
/* #hub,header,.v-navigation-drawer{
  background-color: #0093E9;
background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
} */

body,html{
  /* overflow: hidden; Hide scrollbars */
}
.v-main {
  background-color: #0093e905;
  background-image: linear-gradient(160deg, #0093e980 0%, #80d0c780 100%);
}
.v-main__wrap {
  display: flex;
  flex-direction: column;
}

.fade-leave-active {
  transition: opacity 2s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
* {
    font-family: "Open Sans" !important;
  }
</style>
