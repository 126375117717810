export default {
    Windows: 'mdi-microsoft-windows',
    Mac: 'mdi-apple', 
    htmlaccess: 'mdi-google-chrome',

    POWERED_ON: 'mdi-circle',//'mdi-power',
    POWERED_OFF: 'mdi-power-plug-off',//mdi-power-off
    POWER_STATE_POWERING_OFF: 'mdi-power-off',
    POWER_STATE_POWERING_ON: 'mdi-power-settings',
    POWER_STATE_RESETTING: 'mdi-power-cycle',
    POWER_STATE_SUSPENDING: 'mdi-power-sleep',
    SUSPENDED: 'mdi-sleep',
    SYS_PREP: 'mdi-wrench',


    DESKTOP_NOT_RUNNING: 'mdi-alert-circle',


    LOG_ON : 'mdi-login-variant',
    ALLOCATE	: 'mdi-hand-extended',
    LOG_OFF : 'mdi-exit-run',
    SESSION_TIMEOUT : 'mdi-timer-off',
    DISCONNECT : 'mdi-lan-disconnect',
    RECONNECT  : 'mdi-rotate-right',
    ALLOCATE_FAILURE: 'mdi-hand-back-right-off' ,


    connected: 'mdi-check-network',//mdi-power-off
    disconnected: 'mdi-close-network-outline',//mdi-power-off
}

// DtUserEvent
// Specifies the user event types that are reported by the platform. Values are described below.
// ALLOCATE VM or RDS session is allocated to a user successfully
// ALLOCATE_FAILURE VM or RDS session fails to be allocated to a user
// DISCONNECT User disconnects from a VM or RDS session
// LOG_OFF User logs off of a VM or RDS session
// LOG_ON User logs on to a VM or RDS session
// RECONNECT User reconnects to an existing VM or RDS session
// SESSION_TIMEOUT VM or RDS session times out due to lack of activity
// UNKNOWN


// DtUserEventError
// Specifies the type of user event errors that can occur when desktop allocation fails. Values are described
// below.
// BROKER_PARAM_ERROR Internal error when a parameter required for allocation is incorrect
// or missing
// CONNECTION_TYPE_MISMATCH Session to a full desktop or a remote application currently exists
// and attempt has been made to connect with the other type (full
// desktop or remote application)
// FAILED_TO_LOCK_VM VM for allocation could not be locked for exclusive access
// DESKTOP_UNAVAILABLE VM or RDS session cannot be allocated because either no VMs in a
// pool are available or an unrecognized error occurred
// DESKTOP_NOT_RUNNING VM for allocation was not powered on
// GUEST_OS_NOT_RUNNING Operating system of the VM is not running
// VM_TOOLS_NOT_INSTALLED VM tools have not been installed on the VM for allocation
// VM_TOOLS_NOT_RUNNING VM tools are not running on the VM for allocation
// IP_ADDRESS_UNKNOWN IP address of the VM to be allocated is not known or has not been
// reported by the hypervisor
// IP_ADDRESS_UNREACHABLE IP address of the VM to be allocated is not reachable
// VM_STATE_ERROR Allocation state of the VM is not in the required state (generally,
// “AVAILABLE”)
// AGENT_STATE_ERROR DaaS Agent of the VM is not in the required state ("ACTIVE")
// INCOMPATIBLE_PROTOCOL Reconnect to an existing VM or RDS session and requested
// protocol is not compatible with the one previously used to
// establish the session. User must be logged off of the existing
// session before proceeding with the new session.
// PCOIP_AGENT_ERROR PCoIP agent is not active on the VM or there was an error with the
// PCoIP gateway on the VM
// RAM_SERVICE_FAILURE RAM service failure is when a desktop cannot be reached through
// the dtRAM appliance

// DtVMLifeState
// Specifies the various states of the virtual machine. Value is one of the following:
// ● CLONE_FAILED
// ● CLONING
// ● DESTROYING
// ● JOINING_DOMAIN
// ● NOT_IN_DOMAIN
// ● OFF
// ● READY
// ● STARTING
// ● UNKNOWN

// DtTaskStatus
// Specifies the current status of this pool task. Value is one of the following:
// ● FAILED
// ● OTHER
// ● RUNNING
// ● SUCCESSFUL

// DtVMPowerState
// Specifies the states of power for a VM. Value is one of the following:
// ● POWER_STATE_POWERING_OFF
// ● POWER_STATE_POWERING_ON
// ● POWER_STATE_RESETTING
// ● POWER_STATE_SUSPENDING
// ● POWERED_OFF
// ● POWERED_ON
// ● SUSPENDED
// ● SYS_PREP