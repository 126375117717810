import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)
import intersection from 'lodash.intersection'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: () => import(/* webpackChunkName: "bookmarks" */ '../views/Bookmarks.vue')
  },
  {
    path: '/speedtest',
    name: 'Speedtest',
    component: () => import(/* webpackChunkName: "speedtest" */ '../views/Speedtest.vue')
  },
  {
    path: '/dashboards/:dashboard?/:params?',
    name: 'Dashboards',
    component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards.vue')
  },
  {
    path: '/account',
    name: 'My Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/data-import/:section?',
    name: 'Data Import',
    component: () => import(/* webpackChunkName: "DataImport" */ '../views/DataImport')
  },
  {
    path: '/call-player',
    name: 'Call Recording Player',
    component: () => import(/* webpackChunkName: "CallPlayer" */ '../views/CallPlayer')
  },
  {
    path: '/status',
    name: 'Status',
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue')
  },

  {
    path: '/employment',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue')
  },
  {
    path: '/live-monitoring',
    name: 'Live Monitoring',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/LiveMonitoring.vue')
  },
  {
    path: '/messaging',
    name: 'Messaging',
    component: () => import(/* webpackChunkName: "messaging" */ '../views/Messaging.vue')
  },
  {
    path: '/phone-schedules/:id?',
    name: 'Phone Schedules',
    component: () => import(/* webpackChunkName: "phone-schedule" */ '../views/PhoneSchedule.vue')
  },
  {
    path: '/admin/:section?',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin')
  },
  {
    path: '/vdi',
    name: 'VDI',
    component: () => import(/* webpackChunkName: "vdi" */ '../views/VDI')
  },
  {
    path: '/user-management',
    name: 'User Management',
    component: () => import(/* webpackChunkName: "userManagment" */ '../views/UserManagement')
  },
  {
    path: '/pci',
    name: 'PCI',
    component: () => import(/* webpackChunkName: "vdi" */ '../views/PCI')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "vdi" */ '../views/Maintenance')
  },
  {
    path: '/hue/:groupBy?/:sizeBy?/:department?/:version?',
    name: 'HUE',
    component: () => import(/* webpackChunkName: "hue" */ '../views/Hue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(JSON.stringify(store?.getters?.allPages),to)
  console.log({to})
  console.log(store?.getters?.allPages)
  let page = store?.getters?.allPages && store?.getters?.allPages?.filter(page=>(page.name||'').trim().toLowerCase() === (to.name||'').trim().toLowerCase())[0]
  console.log({page})

  let section = to?.params?.section

  let auth = store?.state?.auth?.claims?.access
  let role = auth?.role || null
  let clients = auth?.clients || []
  let allClients = auth?.allClients || false

  console.log({page,section})
  if (!role || !section || !page || page.access.public){
    page || to.fullPath === '/' ? next() : next('/')
    console.log('Route without check')
  } else if (!page.sections || !page.sections[section]){
    console.log('No Section')
    next()
  } else {
    let pageSection = page.sections[section]
    let allowedRole = !pageSection.roles || (pageSection.roles).indexOf(role)>-1
    let allowedClient = allClients || !pageSection.clients || intersection(pageSection.clients,clients).length>0
    console.log({allowedClient,allowedRole})
    if (!allowedRole || !allowedClient ){
      delete to.params.section
    }
    allowedRole && allowedClient ? next() : next(to)
  }
})

export default router
