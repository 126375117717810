import firebase from 'firebase'
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBfNXJQxXWNolznLaI3UZVomGkF9zAi9Mo",
    authDomain: "vipdesk-hub.firebaseapp.com",
    projectId: "vipdesk-hub",
    storageBucket: "vipdesk-hub.appspot.com",
    messagingSenderId: "159281488336",
    appId: "1:159281488336:web:370b1bd56133936b3119d5",
    measurementId: "G-XPKF9NLE0V"
  }

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase

