import store from '../store'
import firebase from '../firebase'
import msGraph from './msGraph'
// import router from '../router'

const provider = new firebase.auth.OAuthProvider("microsoft.com")
const functions = firebase.functions()

provider.setCustomParameters({
    tenant: "4054b496-d6ce-49ae-a59a-b9508270f922",
})

let accessToken

const login = async () => {
    console.log('login')
    let result = await firebase.auth().signInWithPopup(provider)
    let isNewUser = result.additionalUserInfo.isNewUser
    var credential = result.credential
    console.log('login',{result,credential,isNewUser})
    store.commit('UPDATE_IS_NEW_USER', isNewUser)
    if (isNewUser){

        console.log('SET EVERYTHING UP')
    }
    accessToken = credential.accessToken
    store.commit('UPDATE_MS_TOKEN', accessToken)
    await getClaims()

    // await reauthenticate()
    // let data = await msGraph(accessToken)
    // store.commit('UPDATE_MS_GRAPH', data)
    // let groups = data.groups.map(group => group.id)
    // await setClaims(groups)
}
const logout = async () => {
    await firebase.auth().signOut()
    store.commit('LOGOUT_USER')
    // if (router.currentRoute.fullPath !=='/'){
    //     router.push('/')
    // }
    window.location.reload()
}

const getUser = async ()=>{
    return firebase.auth().currentUser
}

const getGroups = async()=>{
    let getGroups = functions.httpsCallable("getGroups");
    let resp = await getGroups();
    console.log(resp)
    return resp
}

const getUserData = async()=>{
    console.log('getUserData')
    // let getUserDataFn = functions.httpsCallable("getUserData");
    // let resp = await getUserDataFn().catch(err=>{
    //     console.log('getUserData',err)
    // });
    // // await getClaims()
    // console.log('getUserData', resp)
    // console.log('UPDATE_MS_GRAPH',resp)
    // store.commit('UPDATE_MS_GRAPH', resp?.data)
    // return resp
    return 
}

const reauthenticate = async () => {
    console.log('reauthenticate')
    if (firebase.auth().currentUser) {
        var provider = new firebase.auth.OAuthProvider('microsoft.com')
        let result = await firebase.auth().currentUser.reauthenticateWithPopup(provider).catch((error) => {
            if (error.code === 'auth/popup-blocked') {
                alert('Popup Blocked. Please Allow')
                store.commit('UPDATE_MS_GRAPH', { blocked: true })
            } else {
                store.commit('UPDATE_MS_GRAPH', { error })
            }
        })
        let isNewUser = result.additionalUserInfo.isNewUser
        store.commit('UPDATE_IS_NEW_USER', isNewUser)
        console.log('reauthenticate',{result})
        accessToken = result?.credential?.accessToken
        if (accessToken) {
            store.commit('UPDATE_MS_TOKEN', accessToken)
            let data = await msGraph(accessToken)
            store.commit('UPDATE_MS_GRAPH', data)
            console.log({data})
        }
    }
}

const setClaims = async () => {
    console.log('setClaims')
    if (!firebase.auth().currentUser) {
        return null
    }
    let setClaims = functions.httpsCallable("setClaims")
    console.log(store.state)
    console.log(store.state.msGraph)
    console.log(store.state.msGraph.groups)
    let groups = (store.state.msGraph.groups||[]).map(group => group.id)
    console.log({ groups })
    let result = await setClaims(groups)
    // return result
    console.log({result})
    return result
}

const getClaims = async () => {
    console.log('getClaims')
    if (!firebase.auth().currentUser) {
        return null
    }
    await getUserData()

    console.log('getClaims')
    let idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
    console.log('getClaims',idTokenResult)
    console.log('getClaims',idTokenResult.claims)
        store.commit('UPDATE_AUTH', idTokenResult)
    return idTokenResult
}

// firebase.auth().onAuthStateChanged(async (user) => {
//     if (!user) {
//         store.commit('LOGOUT_USER')
//         return
//     }
//     store.commit('UPDATE_USER', user)
//     getClaims()
//     getUserData()
//     // if (!accessToken) {
//     //     console.log('No Token')
//     //     await reauthenticate()
//     // }
// })


export default {
    login,
    reauthenticate,
    logout,
    getClaims,
    setClaims,
    getUser,
    getUserData,
    getGroups
}