import Vue from 'vue'
import dayjs from 'dayjs'
import clone from '../utils/clone'
import icons from '../utils/icons'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from "dayjs/plugin/isBetween"
import advancedFormat from "dayjs/plugin/advancedFormat"
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)
dayjs.extend(duration)
const myMixin = {
  methods: {
    $dayjs:dayjs
  },
  created: function () {
    console.log('component hook called')
  }
}

Vue.mixin({
  methods: {
    dayjs,
    $dayjs: dayjs
  }
})

Vue.filter('timezone', function (value) {
  if (!value || !dayjs(value).isValid()) return value
  return dayjs(value).format('Z')
})

Vue.filter('time', function (value) {
  if (!value) return value
    if (dayjs(value).isValid()){
      let time = dayjs(value)
      let format = 'h:mma'
      return  dayjs(time).format(format)
    }
    let parts = value.split(':')
    let date = dayjs().hour(+parts[0]).minute(+parts[1]).second(0)
    let format = 'h:mma'
    return date.format(format)
  })

  Vue.filter('duration', function (value) {
    if (!value) return value
      return dayjs.duration(value, "milliseconds").humanize()
    })
  

  Vue.filter('timeSh', function (value) {
    if (!value) return value
      if (dayjs(value).isValid()){
        let time = dayjs(value)
        let format = 'h:mma'
        if (time.format('mm')=='00'){
          format = 'ha'
        }
        return  dayjs(time).format(format)
      }
      let parts = value.split(':')
      let date = dayjs().hour(+parts[0]).minute(+parts[1]).second(0)
      let format = 'h:mma'
      if (date.format('mm')=='00'){
        format = 'ha'
      }
      return date.format(format)
    })
  

  Vue.filter('ago', function (value) {
    if (!value) return value
      if (dayjs(value).isValid()){
        return  dayjs(value).fromNow()
      }
    })
  
    
  Vue.filter('icon', function (value) {
    if (!value) return value
    return icons[value] || value
    })
  


  Vue.filter('date', function (value) {
      const format = 'ddd MMM D, YYYY'
    if (!value) return value
    if (Array.isArray(value)){
        return value.map(day=>{
            return dayjs(day).format(format)
        }).join(' to ')
    } else {
        return dayjs(value).format(format)
    }

  })

  Vue.filter('annualDate', function (value) {
    const format = 'ddd MMM D [ (Yearly)]'
  if (!value) return value
  if (Array.isArray(value)){
      return value.map(day=>{
          return dayjs(day).format(format)
      }).join(' to ')
  } else {
      return dayjs(value).format(format)
  }

})

  Vue.filter('day', (dayArray) => {
    if (!dayArray || !dayArray.length){
        return null
      }
      const days = JSON.stringify(clone(dayArray).sort())
      const Weekdays = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].sort())
      const Weekend = JSON.stringify(['Saturday', 'Sunday'].sort())
      const MonSat = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday",'Saturday'].sort())
      const Daily = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday",'Saturday', 'Sunday'].sort())
        if (days == Weekdays){
          return 'Weekdays'
        } else   if (days == MonSat){
          return 'Mon-Sat'
        } else if (days == Weekend){
          return 'Weekends'
        } else   if (days == Daily){
          return 'Daily'
        }
          return dayArray.map(day=>day+'s').join(', ')
      })


      export default myMixin