<template>
  <v-menu open-on-hover bottom offset-y v-if="user">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-avatar size="24" v-if="profile && profile.photo">
          <img :src="profile.photo" :alt="user.displayName" />
        </v-avatar>

        <div class="ml-2">{{ user.displayName }}</div>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item v-if="profile && profile.claims.role">
          <v-list-item-title>Role: {{ profile.claims.role }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="reauthenticate">
          <v-list-item-title>Refresh Login</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setClaims">
          <v-list-item-title>Refresh Role</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="logout">Logout</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
  <v-btn v-else color="primary dark" @click="login">Login</v-btn>
</template>

<script>
import { mapState } from "vuex";
import auth from "../utils/auth";
export default {
  computed: {
    ...mapState(["user", "profile"]),
  },
  methods: {
    async setClaims() {
      await auth.reauthenticate();
      await auth.setClaims();
      await auth.reauthenticate();
    },
    reauthenticate() {
      auth.reauthenticate();
    },
    logout() {
      auth.logout();
    },
    login() {
      auth.login();
    },
  },
};
</script>

<style>
</style>