
// import store from '../store'

let msData = async (token) => {
    let resp= await Promise.all([getMe(token),getMyTeams(token),getMyPhoto(token),getMyManager(token),getMyTeam(token)])
    let account = resp[0]
    let groups = resp[1]
    let photo = resp[2]
    let manager = resp[3]
    let myTeam = resp[4]
    // let users = resp[5]
    let result = {account,groups,photo,manager,myTeam,token}
    console.log(resp,{result})
    // store.commit('UPDATE_MS_GRAPH', result)

    return result
}

const getMe = async (token)=>{
    let resp = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    let data = await resp.json()
    return data
}

const getMyManager = async (token)=>{
    let resp = await fetch('https://graph.microsoft.com/v1.0/me/manager', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).catch(err=>{
        console.log(err)
        return null
    })
    let data = await resp.json()
    return data
}

const getMyTeam = async (token)=>{
    let resp = await fetch('https://graph.microsoft.com/v1.0/me/directReports', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    let data = await resp.json()
    return data.value
}

const getMyPhoto = async (token)=>{
    let resp = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    console.log(resp)
    let data = await resp.blob().then( blob => new Promise( callback =>{
        let reader = new FileReader() ;
        reader.onload = function(){ callback(this.result) } ;
        reader.readAsDataURL(blob) ;
    }) ) ;
    return data
}

const getMyTeams = async (token)=>{
    let resp = await fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    let data = await resp.json()
    return (data && data.value ? data.value||[] : []).map(group=>({id: group.id, name: group.displayName}))
}


// let initiated
// const getOrgChart = async (token,userPrincipalName)=>{
//     if (!initiated && !userPrincipalName){
//         userPrincipalName = 'Sally.Hurley@VIPdeskConnect.com'
//         initiated=true
//     } else if (initiated && !userPrincipalName){
//         return null
//     }
//     let url = `https://graph.microsoft.com/v1.0/users/${userPrincipalName}/directReports`
//     let resp = await fetch(url, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     })
//     let users = []
//     let data = await resp.json()
//     console.log(data)
//     if (data.value && data.value.length){
//         users = [...users, ...data.value]
//         console.log('Lookup',data.value.map(user=>user.userPrincipalName) )
//         let nextUsers = await Promise.all(data.value.map(async user=> await getOrgChart(token,user.userPrincipalName)))
//         console.log(nextUsers.length,nextUsers.filter(user=>user).length)
//         users = [...users, ...nextUsers.filter(user=>user)]
//     }
//     console.log('DONE',users,users.length)

//     return users
// }


// const getAllUsers = async (token,url='https://graph.microsoft.com/v1.0/users', users=[])=>{
//     let resp = await fetch(url, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     })
//     let data = await resp.json()
//     console.log(data)
//     users = [...users, ...data.value]
//     console.log(users,data['@odata.nextLink'])
//     return data['@odata.nextLink'] ? await getAllUsers(token,data['@odata.nextLink'],users) :  users
// }

export default msData