import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNewUser: false,
    user: null,
    userData: null,
    profile: null,
    auth: null,
    msGraph: null,
    msToken: null,
    pages: [],
    publicPages: [],
    phoneSchedules: [],
    importDatasets: [],
    clients: [],
    dashboards: [],
    bookmarks: {},
    dataLoaded: false,
    permissions: [],
    saving: false
  },
  getters:{
    allPages(state){
      return [...state.publicPages,...state.pages].sort((a,b)=>a.weight<b.weight ? -1 : a.weight>b.weight ? 1 : 0  )
    },
    pageVariables(state){
      return state.pages.reduce((obj,page)=>{
        obj[page.id] = page.variables || {}
        return obj
      },{})
    }
  },
  mutations: {
    UPDATE_IS_NEW_USER(state, payload){
      state.isNewUser = payload
    },
    SET_SAVING(state, payload){
      state.saving = payload
    },
    SET_DATA_LOADED(state){
      state.dataLoaded = true
    },
    UPDATE_USER(state, payload){
      state.user = payload
    },
    UPDATE_PERMISSIONS(state, payload){
      state.permissions = payload
    },
    UPDATE_PROFILE(state, payload){
      state.profile = payload
    },
    UPDATE_MS_GRAPH(state, payload){
      state.msGraph = payload
    },
    UPDATE_AUTH(state, payload){
      state.auth = payload
    },
    UPDATE_MS_TOKEN(state, payload){
      state.msToken = payload
    },
    UPDATE_PAGES(state, payload){
      state.pages = payload
    },
    UPDATE_PUBLIC_PAGES(state, payload){
      state.publicPages = payload
    },
    UPDATE_CLIENTS(state, payload){
      state.clients = payload
    },
    UPDATE_DASHBOARDS(state, payload){
      state.dashboards = payload
    },
    UPDATE_IMPORT_DATASETS(state, payload){
      state.importDatasets = payload
    },
    UPDATE_PHONE_SCHEDULES(state, payload){
      state.phoneSchedules = payload
    },
        UPDATE_BOOKMARKS(state, payload){
      state.bookmarks = payload
    },
    LOGOUT_USER(state){
      state.profile = null
      state.user = null
      state.auth = null
      state.msGraph = null
      state.msToken = null
      state.permissions = null//???? needed>
    }
  },
  actions: {
  },
  modules: {
  }
})
